import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de 4 a 13 cm de diàmetre que el podem trobar de vàries formes, convex, pla i deprimit al centre i en forma d’embut depenent de l’edat. És untuós al tacte, zonat i de color crema rosat a ocraci groguenc. Les làmines d’adnades a decurrents, color ocraci cremós primer, després ocraci brut, taronja ocraci a roig brunenc quan es toquen. Les espores també ocràcies en massa, subgloboses i ornamentades amb crestes, de 9-13,5 x 7,5-11 micres. El peu és cilíndric, tomentós al principi, llis i glabre més tard, de color blanc cremós, tornant més fosc amb l’edat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      